var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"WwK7rkIzT_vQ51_PaFyoA"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://b0693d72fe8cd3d60415c253f4f90488@o4507766738059264.ingest.us.sentry.io/4507766747758592',
  environment: process.env.NEXT_PUBLIC_INFRASTRUCTURE_ENV,

  // restrict sentry to only monitor these urls
  // allowUrls: [
  //   /https?:\/\/(www\.)?spherepay\.co\/(login|access-request|dashboard\/payouts|pay\/\S+|ramp)/,
  // ],

  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration()],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  replaysSessionSampleRate:
    process.env.NEXT_PUBLIC_INFRASTRUCTURE_ENV === 'production' ? 0.05 : 1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate:
    process.env.NEXT_PUBLIC_INFRASTRUCTURE_ENV === 'production' ? 0.5 : 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  enabled: process.env.NEXT_PUBLIC_INFRASTRUCTURE_ENV !== 'development',
});
