import React from 'react';
import { Accordion as AccordionComponent } from '@/app/[lang]/(landing)/components/Accordion';
import { AppBadge, AppBadgeColor } from '@/common/components/AppBadge';
import { cn } from '@/common/utils/cn';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from 'lucide-react';

const Accordion = AccordionComponent as any;

interface SolutionsAccordionProps {
  children: React.ReactNode;
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
}

export function SolutionsAccordion({
  children,
  value,
  onValueChange,
  className,
}: SolutionsAccordionProps) {
  return (
    <Accordion
      type='single'
      className={className}
      value={value}
      onValueChange={onValueChange}
    >
      {children}
    </Accordion>
  );
}

interface SolutionsAccordionItemProps {
  value: string;
  title: string;
  badgeNumber?: number;
  badgeColor?: AppBadgeColor;
  comingSoon?: boolean;
  children: React.ReactNode;
  className?: string;
}

SolutionsAccordion.Item = function SolutionsAccordionItem({
  value,
  title,
  badgeNumber,
  badgeColor = 'primary',
  comingSoon,
  children,
  className = '',
}: SolutionsAccordionItemProps) {
  const { t } = useTranslation('general');

  return (
    <Accordion.Item
      value={value}
      className={cn('max-md:border-b md:rounded-lg', className)}
    >
      <Accordion.Trigger className='text-left py-2 md:px-4 font-semibold text-lg'>
        <div className='flex items-center gap-x-2'>
          {badgeNumber !== undefined && (
            <AppBadge
              color={badgeColor}
              className='p-0 size-6 items-center justify-center font-semibold'
            >
              {badgeNumber}
            </AppBadge>
          )}
          {title}
          {comingSoon && <AppBadge color='purple'>{t('comingSoon')}</AppBadge>}
        </div>
        <PlusIcon className='size-6 text-[#7E7E7E] shrink-0 text-muted-foreground transition-transform duration-200' />
      </Accordion.Trigger>
      <Accordion.Content className='flex flex-col gap-y-4 text-subtle text-base md:px-4 md:pr-12 font-light'>
        {children}
      </Accordion.Content>
    </Accordion.Item>
  );
};

SolutionsAccordion.Content = Accordion.Content;
