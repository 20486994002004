'use client';
import { Accordion as AccordionComponent } from '@landing/components/Accordion';
import { PlusIcon } from 'lucide-react';
import React from 'react';

const Accordion = AccordionComponent as any;

interface FAQItemProps {
  question: string;
  answer: string | string[];
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  return (
    <Accordion.Item
      value={question}
      className='max-md:border-b md:rounded-lg transition-colors data-[state=open]:bg-surface-1 hover:data-[state=closed]:bg-background-dim-hover rounded-lg py-4 max-md:px-4'
    >
      <Accordion.Trigger className='text-left py-2 md:px-4 font-normal text-lg'>
        {question}
        <PlusIcon className='size-6 text-[#7E7E7E] shrink-0 text-muted-foreground transition-transform duration-200' />
      </Accordion.Trigger>
      <Accordion.Content className='flex flex-col gap-y-4 text-subtle text-base md:px-4 md:pr-12 font-light text-[#475467]'>
        {Array.isArray(answer) ? (
          <ul className='list-decimal pl-5'>
            {answer.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        ) : (
          <p>{answer}</p>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
};

interface FAQListProps {
  items: {
    question: string;
    answer: string | string[];
  }[];
  defaultValue?: string;
}

const FAQList: React.FC<FAQListProps> = ({
  items,
  defaultValue = items[0].question,
}) => {
  return (
    <Accordion
      type='multiple'
      defaultValue={[defaultValue]}
      className='max-w-2xl w-full mx-auto [&[data-state="open"]+[data-state="open"]]:*:mt-4 *:transition-[margin]'
    >
      {items.map((item) => (
        <FAQItem
          key={item.question}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </Accordion>
  );
};

export { FAQList as FAQ };
